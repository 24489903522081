<script setup>
const emit = defineEmits(['close'])
defineProps({
  type: {
    type: String,
    required: true,
    default: 'warning',
  },
  cantClose: {
    type: Boolean,
    required: false,
    default: false,
  },
})

function close() {
  emit('close')
}
</script>

<template>
  <div
    class="font-averta-pe text-blue-dark flex items-center rounded-md bg-gray-50 px-4 py-3 text-base"
    :class="{
      'bg-red-300': type === 'error',
      'bg-orange-300': type === 'warning',
      'bg-green-300': type === 'success',
    }"
  >
    <button
      v-if="!cantClose"
      type="button"
      aria-label="Close"
      class="mr-3 flex h-8 w-8 flex-none items-center justify-center rounded-full bg-white text-xl font-medium"
      @click="close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <slot></slot>
  </div>
</template>
